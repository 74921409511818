
<template>
    <div>
        <div class="right-menu shipping-right">

            <subHeader pageName="NO INDEX URLS" />

            <tutorialsAction />

            <div class="db_top">
                <div class="db-container">

                    <moduleStats/>

                    <!-- search block starts here -->
                    <div class="formOptionsBox">
                        <div class="searchOptionsWrap">
                            <div class="searchBlock" id="no-index-url-search">
                                <form @submit.prevent="">
                                    <div class="searchIconWrap">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.491 17.49">
                                            <path id="Path_10" data-name="Path 10" d="M16,17.49h0L11,12.5v-.79l-.27-.28a6.512,6.512,0,1,1,.7-.7l.28.27h.79l4.99,5L16,17.489ZM6.5,2A4.5,4.5,0,1,0,11,6.5,4.505,4.505,0,0,0,6.5,2Z" fill="#848484"/>
                                        </svg>
                                    </div>
                                    <input
                                        type="text"
                                        name="search"
                                        id="search"
                                        ref="searchInput"
                                        placeholder="Search"
                                        v-model="searchedItem"
                                        @input="searchNoIndexUrl()"
                                        :disabled="tourIsActive"
                                    />
                                </form>
                            </div>
                            <div class="filterShowTabsRow" v-if="searchedItem.length > 0">
                                <div class="filterShowTabsListSearchedQuery">
                                    <p>Search results for "{{ searchedItem.length > 20 ? searchedItem.substr(0, 20) + "..." : searchedItem }}".</p>
                                </div>
                                <div class="filterShowTabsResetBtn">
                                    <button @click.prevent="clearSearch">Clear</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- search block ends here -->

                    <!-- data table starts here -->
                    <div class="tableMainWrapBox" style="margin-top: 25px;position: relative;" id="preview-record">
                        <div class="tableScrollWrap" ref="noIndexUrlsTable">
                            <table>
                                <thead>
                                    <tr>
                                        <th>
                                            <div class="ctmCheckWrap">
                                                <label class="ctmTableCheckContainer">
                                                    <input 
                                                        type="checkbox"
                                                        @click="toggleIsCheckedAll()"
                                                        :checked="checkedItems.length != 0 && checkedItems.length == noIndexUrlsList.length"
                                                        :disabled="tourIsActive || this.previewForm.id != ''"
                                                    >
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </th>
                                        <th class="ctmPr-3">
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="URL">URL</p>
                                            </div>
                                        </th>
                                        <th>
                                            <div class="ctmTableTitleWrap">
                                                <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody v-if="noIndexUrlsLoading">
                                    <tr v-for="i in 5" :key="i">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="600"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody v-else>
                                    <tr v-if="noIndexUrlsList && noIndexUrlsList.length == 0">
                                        <td colspan="7" style="text-align:center"><p class="mb-0">No Index URLs Are Not Available</p></td>
                                    </tr>
                                    <tr v-for="(noIndexUrl, index) in noIndexUrlsList" :key="index">

                                        <!-- checkbox block starts here -->
                                        <td @click="previewNoIndexUrl(noIndexUrl,index,'input',$event)">
                                            <div class="ctmCheckWrap" data-open="true">
                                                <label class="ctmTableCheckContainer" data-open="false" @click.prevent=" noIndexUrl.id != -1 ? checkedItemHandle(noIndexUrl.id) : ''">
                                                    <input 
                                                        type="checkbox" 
                                                        v-model="checkedItems" 
                                                        :value="noIndexUrl.id" :disabled="tourIsActive || noIndexUrl.id == -1" 
                                                        data-open="false"
                                                    >
                                                    <span class="checkmark" data-open="false"></span>
                                                </label>
                                            </div>
                                        </td>
                                        <!-- checkbox block ends here -->

                                        <!-- URL block starts here -->
                                        <td class="mainTableTitle" @click="previewNoIndexUrl(noIndexUrl,index,'input',$event)">
                                            <div class="editFieldTitle" data-open="true" id="url-field-3">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a no index URL"
                                                    v-model="noIndexUrl.url" 
                                                    @focus="noIndexUrlSelection(noIndexUrl)" 
                                                    @blur="handleOTGUpdate(noIndexUrl,index)" 
                                                    :disabled="tourIsActive"
                                                    data-open="false"
                                                >
                                            </div>
                                        </td>
                                        <!-- URL block ends here -->

                                        <!-- action block starts here -->
                                        <td @click="previewNoIndexUrl(noIndexUrl,index,'input',$event)">
                                            <div class="ctmDataTableActionBtn" v-if="isDeletingItem(noIndexUrl.id) && deleteLoader" data-open="true">
                                                <loaderBtn />
                                            </div>
                                            <div class="ctmDataTableActionBtn" data-open="true" v-else>
                                                <button class="delFunction" @click.prevent="deleteNoIndexUrlHandle(noIndexUrl,index)" :disabled="tourIsActive" data-open="false">
                                                    <i aria-hidden="true" class="fa fa-trash-o" data-open="false"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <!-- action block ends here -->

                                    </tr>

                                    <!-- load more block starts here -->
                                    <tr v-if="isPending">
                                        <td>
                                            <div class="ctmCheckWrap">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="20"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td class="mainTableTitle">
                                            <div class="editFieldTitle">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="600"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tableBodyCtmTxt">
                                                <vue-skeleton-loader
                                                    type="rect"
                                                    :width="100"
                                                    :height="20"
                                                    animation="fade"
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- load more block ends here -->

                                </tbody>
                            </table>
                        </div>

                        <!-- add/update coupon form starts here -->
                        <div class="productSlideBox" ref="viewInputForm" :class="previewForm.id ? 'activeRow': ''" >
                            <div class="tableActionBtns" id="no-index-url-preview">
                                <div class="productTableSideBar">
                                    <div class="row">
                                        <div class="col-md-8" id="url-field-2">
                                            <div class="viewOrderTitle">
                                                <input 
                                                    type="text" 
                                                    placeholder="Write a no index URL" 
                                                    v-model="previewForm.url"
                                                    :disabled="tourIsActive"
                                                >
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="orderViewNav">
                                                <ul>
                                                    <el-tooltip class="box-item" effect="dark" content="Expand" placement="top-start">
                                                        <li class="sm-extand-hide">
                                                            <button @click.prevent="expand" :disabled="tourIsActive" id="expand-form">
                                                                <svg viewBox="0 0 21 21" xmlns="http://www.w3.org/2000/svg">
                                                                    <g fill="none" fill-rule="evenodd" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" transform="translate(2 2)">
                                                                        <path d="m16.5 5.5v-4.978l-5.5.014"/>
                                                                        <path d="m16.5.522-6 5.907"/>
                                                                        <path d="m11 16.521 5.5.002-.013-5.5"/>
                                                                        <path d="m16.5 16.429-6-5.907"/>
                                                                        <path d="m.5 5.5v-5h5.5"/>
                                                                        <path d="m6.5 6.429-6-5.907"/>
                                                                        <path d="m6 16.516-5.5.007v-5.023"/>
                                                                        <path d="m6.5 10.5-6 6"/>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                    <el-tooltip class="box-item" effect="dark" content="Close" placement="top-start">
                                                        <li>
                                                            <button @click.prevent="closePreview" id="closeSide" :disabled="tourIsActive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="29.446" height="33.409" viewBox="0 0 29.446 33.409">
                                                                    <g id="Group_20906" data-name="Group 20906" transform="translate(362.041 -2185.5)">
                                                                        <path id="Path_57522" data-name="Path 57522" d="M-361.041,2186.648v31.409" transform="translate(0 -0.148)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                        <g id="Group_20905" data-name="Group 20905" transform="translate(0 -0.5)">
                                                                            <path id="Path_57523" data-name="Path 57523" d="M-354.018,2202.5h20.265" transform="translate(0 0)" fill="none" stroke="#000" stroke-linecap="round" stroke-width="2"/>
                                                                            <path id="Path_57524" data-name="Path 57524" d="M-342.6,2193.747l8,9.15-8,8.85" transform="translate(1 -0.247)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </button>
                                                        </li>
                                                    </el-tooltip>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="sectionTabsLink">
                                    <ul onclick="window.myFunction(event)">
                                        <li v-if="!tourIsActive">
                                            <a class="active" id="no-index-url-navbtn" href="#noIndexUrlDetails">No Index URL Info</a>
                                        </li>
                                    </ul>
                                    <div class="updateBtnBox" v-if="noIndexUrlIsSaving">
                                        <loaderBtn/>
                                    </div>
                                    <div class="updateBtnBox" id="save-no-index-url-btn" v-else>
                                        <button 
                                            form="no-index-url-information" 
                                            button="submit" 
                                            @click="validate()" 
                                            :disabled="tourIsActive"
                                        >
                                            {{ previewForm.id == -1 ? "Save" : "Update" }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <ValidationObserver ref="noIndexUrlFormObserver">
                                <div class="productTableInfoBlock">
                                    <div class="productTableInfoWhiteBox" id="noIndexUrlDetails">
                                        <form id="no-index-url-information" @submit.prevent="validate()">
                                            <div class="tableFromFieldBox">
                                                <label for="url-field-1">URL<span>*</span></label>
                                                <ValidationProvider name="url" :rules="{required:true,regex:'(^https?://)',max:500}"  v-slot="{ errors }">
                                                    <div class="tableFromFieldItem">
                                                        <input 
                                                            type="text" 
                                                            id="url-field-1" 
                                                            name="url-field-1" 
                                                            class="form-control" 
                                                            placeholder="URL"
                                                            v-model="previewForm.url"
                                                            :disabled="tourIsActive"
                                                        />
                                                    </div>
                                                    <span class="text-danger" v-if="errors">{{ errors[0] ? errors[0] + ' ' + '(must start with "https://") ' : '' }} </span>
                                                </ValidationProvider>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <!-- add/update coupon form ends here -->

                    </div>
                    <!-- data table ends here -->

                    <!--bottom action bar starts here-->
                    <div class="row product-bottom-bar m-0" v-if="checkedItems.length > 1">
                        <div class="col-md-6 model-footer-main1">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 mr-5" @click.prevent="toggleIsCheckedAll()" v-if="checkedItems.length != noIndexUrlsList.length">
                                    <i class="fa fa-check-square-o" aria-hidden="true"></i>
                                    <p>SELECT ALL</p>
                                </a>
                                <a href="" class="model-footer-1" @click.prevent="clearSelection()">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.026 14.027">
                                        <path id="cross-small" d="M18.969,6.023h0a1.079,1.079,0,0,0-1.525,0L12.5,10.971,7.549,6.023a1.079,1.079,0,0,0-1.525,0h0a1.079,1.079,0,0,0,0,1.525L10.971,12.5,6.023,17.443a1.079,1.079,0,0,0,0,1.525h0a1.079,1.079,0,0,0,1.525,0L12.5,14.021l4.947,4.947a1.079,1.079,0,0,0,1.525,0h0a1.079,1.079,0,0,0,0-1.525L14.021,12.5l4.947-4.947A1.079,1.079,0,0,0,18.969,6.023Z" transform="translate(-5.483 -5.483)" fill="#fd4d5d" stroke="#fd4d5d" stroke-width="0.3"/>
                                    </svg>
                                    <p>CLEAR SELECTION</p>
                                </a>
                            </div>
                            <div class="model-footer-2">{{ checkedItems.length }}</div>
                        </div>
                        <div class="col-md-6 model-footer-main1 product-row-reverse">
                            <div class="bottom-bar">
                                <a href="" class="model-footer-1 ml-4" @click.prevent="bulkActionHandle('delete')">
                                    <i class="fa fa-trash-o" aria-hidden="true"></i>
                                    <p>DELETE</p>
                                </a>
                            </div>
                        </div>
                    </div>
                    <!--bottom action bar ends here-->

                </div>
            </div>

        </div>

        <v-tour name="noIndexUrlsTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

    </div>
</template>

<script>
import {MessageBox, Message, Loading} from 'element-ui'
import _ from 'lodash';
import subHeader from "@/components/subHeader.vue";
import moduleStats from "@/components/moduleStats.vue";
import { mapGetters } from 'vuex';
import tutorialsAction from "@/components/tutorialsAction.vue";
import loaderBtn from "@/components/loaderBtn.vue";

export default {
    name: 'noIndexUrls',
    metaInfo() {
        return {
            title: 'SEO | No Index URLs | YeetCommerce',
        };
    },
    data() {
        return {
            //search start
            searchedItem:'',
            //search end

            //no index URLs start
            noIndexUrlsList:[],
            noIndexUrlsLoading:false,
            previewForm:{
                id:'',
                url:'',
                isTouched:false,
            },
            defaultPreviewForm:{
                id:'',
                url:'',
                isTouched:false,
            },
            tempNoIndexUrl:{
                id:'',
                url:'',
                isTouched:false,
            },
            tempPreviewNoIndexUrl:{
                id:'',
                url:'',
                isTouched:false,
            },
            noIndexUrlSaved:false,
            noIndexUrlIsSaving:false,
            //no index URLs start

            //checked items start
            checkedItems:[],
            deletingItems:[],
            deleteLoader:false,
            timer: undefined,
            isMasterSearched:false,
            //checked items end

            //tour start
            tourOptions: {
                useKeyboardNavigation: false,
                labels: {
                    buttonSkip: 'Skip Tour',
                    buttonPrevious: 'Previous',
                    buttonNext: 'Next',
                    buttonStop: 'Finish Tour'
                }
            },
            tourCallbacks: {
                onPreviousStep: this.handlePreviousStep,
                onNextStep: this.handleNextStep,
                // onStart: this.handleTourStart,
                onStop: this.handleTourStop,
                onFinish: this.handleTourFinish,
                onSkip: this.handleTourSkip
            },
            tourSteps:[
                //header steps
                {
                    target: '#tour-start-btn',
                    content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#master-search',
                    content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    },
                },
                {
                    target: '#add-new-dropDown',
                    content: `<strong>Add New Record</strong><br>Enables you to add new orders, products and categories on the go.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#help-link',
                    content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                {
                    target: '#user-options-dropdown',
                    content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
                    params: {
                        highlight: true,
                        enableScrolling: false
                    }
                },
                //search bar step
                {
                    target: '#no-index-url-search',
                    content: `<strong>Search No Index URL</strong><br>It enables you to search specific no index url.`,
                    params: {
                        highlight: true,
                        enableScrolling: false,
                        placement: 'bottom'
                    }
                },
                //form steps
                {
                    target: '#preview-record',
                    content: `<strong>No Index URLs Table</strong><br>You can preview and edit the added no index URL by clicking on each individual record present in the table.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'top'
                    }
                },
                {
                    target: '#add-new-record',
                    content: `Add a <strong>No Index URL</strong><br>By clicking this button, you can add a new no index url and open a form to input and save the details.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#no-index-url-preview',
                    content: `<strong>No Index URL Details Form</strong><br>This form can be used to add details about the no index URL and save them.`,
                    params: {
                        highlight: false,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#url-field-1',
                    content: `<strong>URL</strong><br>URL can be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#url-field-3',
                    content: `<strong>URL</strong><br>URL can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#url-field-2',
                    content: `<strong>URL</strong><br>URL can also be added using this field.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'bottom'
                    }
                },
                {
                    target: '#expand-form',
                    content: `<strong>Expand Form</strong><br>Form can be expanded or collapsed by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#closeSide',
                    content: `<strong>Close Form</strong><br>Form can be closed using this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
                {
                    target: '#save-no-index-url-btn',
                    content: `<strong>Save No Index URL Details</strong><br>Added no index URL details can be saved by clicking this button.`,
                    params: {
                        highlight: true,
                        enableScrolling: true,
                        placement: 'left'
                    }
                },
            ],
            tourIsActive:false,
            //tour end
        }
    },
    components:{
        subHeader,
        tutorialsAction,
        moduleStats,
        loaderBtn
    },
    computed:{
        ...mapGetters({
            settings:'settings_module/settings',
            noIndexUrls:'no_index_urls_module/urls',
            noIndexUrlsCurrentPage:'no_index_urls_module/currentPage',
            noIndexUrlsLastPage:'no_index_urls_module/lastPage',
            noIndexUrlsSearchKeyword:'no_index_urls_module/keyword',
            isPending: 'no_index_urls_module/isPending'
        })
    },
    watch:{
        'noIndexUrls':{
            handler:function(value){

                this.noIndexUrlsLoading = false

                if(this.previewForm.id == -1){
                    
                    this.noIndexUrlsList = _.cloneDeep(value)

                    this.noIndexUrlsList.unshift(this.previewForm)

                }else if(this.tempPreviewNoIndexUrl.id != ''){
                    
                    this.noIndexUrlsList = _.cloneDeep(value)

                    this.noIndexUrlsList[this.previewForm.index] = this.previewForm

                }else{

                    this.noIndexUrlsList = _.cloneDeep(value)

                }


                if(this.isMasterSearched){

                    this.previewSearchedNoIndexUrl(this.noIndexUrlsList[0],0)

                    this.isMasterSearched = false

                }

            },deep:true
        },
        'previewForm':{
            handler:function(value){

                if(value.id == -1 && value.url){

                    value.isTouched = true

                }else{

                    value.isTouched = false

                }

            },deep:true
        },
    },
    methods:{

        //infinite scroll start
        handleScrollEvent(event){

            let element = this.$refs.customersTable;

            let maxScrollTop = element.scrollHeight - element.clientHeight;

            if(element.scrollTop >= maxScrollTop - 100){

                if(this.isPending == false && this.noIndexUrlsCurrentPage != this.noIndexUrlsLastPage){

                    this.$store.dispatch("no_index_urls_module/fetchStoreNoIndexUrlsRequest",{size: 10, page: this.noIndexUrlsCurrentPage + 1, loadData: 'more'});
                    
                }

            }

        },
        //infinite scroll end

        //OTG update start
        noIndexUrlSelection(listedNoIndexUrl){

            this.tempNoIndexUrl = _.cloneDeep(listedNoIndexUrl);

        },
        async handleOTGUpdate(listedNoIndexUrl,index){

            if(this.previewForm.id == ''){
            
                if(listedNoIndexUrl.id != -1){
                
                    let isRequired = false;

                    if(listedNoIndexUrl.url.length == 0){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The URL field is required.",
                        });

                        isRequired = true;

                    }

                    if(listedNoIndexUrl.url.length > 500){

                        this.$notify({
                            type: 'warning',
                            title: 'Alert',
                            message: "The URL field may not be greater than 500 characters.",
                        });

                        isRequired = true;

                    }

                    if(isRequired){

                        this.noIndexUrlsList[index].url = this.tempNoIndexUrl.url;

                    }

                    let isChanged = false;

                    if(this.tempNoIndexUrl.url != listedNoIndexUrl.url){

                        isChanged = true;

                    }

                    if(isChanged){

                        MessageBox.confirm(
                            'You have unsaved changes, save and proceed?',
                            'Confirm',
                            {
                                distinguishCancelAndClose: true,
                                confirmButtonText: 'Save',
                                cancelButtonText: 'Discard Changes',
                            }
                        ).then(async () => {

                            let formData = new FormData()
                            
                            formData.append('id',listedNoIndexUrl.id);
                            formData.append('url',listedNoIndexUrl.url);

                            let loader = Loading.service({
                                text: "The no index URL is being updated. Please wait!",
                                fullscreen: true,
                            });

                            try{
                                let res = await this.$axios.post('/no-index-url/update',formData);
                                if(res.data.status_code == "1322"){
                                 
                                    this.$notify({
                                        type: 'success',
                                        title: 'Success',
                                        message: res.data.message,
                                    });

                                    let payload = {
                                        index: index,
                                        updatedUrl: res.data.url
                                    }

                                    this.$store.commit('no_index_urls_module/update_no_index_url_list',payload);

                                }
                            }catch(error){

                                this.noIndexUrlsList[index].url = this.tempNoIndexUrl.url;                  

                                this.tempNoIndexUrl = [];

                                if(error.response){
                                    
                                    if(error.response.data.error.id){

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error.id[0],
                                        });

                                    }else if(error.response.data.error.includes("already been registered")){
                                    
                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.error,
                                        });

                                    }else if(error.response.data.error.includes("doesn't support")){

                                        let subscriptionPath = window.location.origin + "/subscription";

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            dangerouslyUseHTMLString: true,
                                            message: error.response.data.error 
                                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                        });

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.response.data.message,
                                        });

                                    }

                                }else{

                                    this.$message({
                                        type: 'error',
                                        showClose: true,
                                        message: error.message,
                                    });
                                    
                                }

                            }finally{

                                loader.close();

                            }

                        }).catch((error) => {

                            console.log(error);

                            this.noIndexUrlsList[index].url = this.tempNoIndexUrl.url;                      

                            this.tempNoIndexUrl = [];

                            this.$notify({
                                type: "info",
                                title: "Changes Discarded",
                                message: "Changes have been successfully discarded.",
                            });

                        });

                    }

                }

            }else{

                if(listedNoIndexUrl.id != -1){

                    if(listedNoIndexUrl.id != this.previewForm.id){

                        let isRequired = false;

                        if(listedNoIndexUrl.url.length == 0){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The URL field is required.",
                            });

                            isRequired = true;

                        }

                        if(listedNoIndexUrl.url.length > 500){

                            this.$notify({
                                type: 'warning',
                                title: 'Alert',
                                message: "The URL field may not be greater than 500 characters.",
                            });

                            isRequired = true;

                        }

                        if(isRequired){

                            this.noIndexUrlsList[index].url = this.tempNoIndexUrl.url;

                        }

                        let isChanged = false;

                        if(this.tempNoIndexUrl.url != listedNoIndexUrl.url){

                            isChanged = true;

                        }

                        if(isChanged){

                            MessageBox.confirm(
                                'You have unsaved changes, save and proceed?',
                                'Confirm',
                                {
                                    distinguishCancelAndClose: true,
                                    confirmButtonText: 'Save',
                                    cancelButtonText: 'Discard Changes',
                                }
                            ).then(async () => {

                                let formData = new FormData()
                                
                                formData.append('id',listedNoIndexUrl.id);
                                formData.append('url',listedNoIndexUrl.url);

                                let loader = Loading.service({
                                    text: "The no index URL is being updated. Please wait!",
                                    fullscreen: true,
                                });

                                try{
                                    let res = await this.$axios.post('/no-index-url/update',formData);
                                    if(res.data.status_code == "1322"){
                                    
                                        this.$notify({
                                            type: 'success',
                                            title: 'Success',
                                            message: res.data.message,
                                        });

                                        let payload = {
                                            index: index,
                                            updatedUrl: res.data.url
                                        }

                                        this.$store.commit('no_index_urls_module/update_no_index_url_list',payload);

                                    }
                                }catch(error){

                                    this.noIndexUrlsList[index].url = this.tempNoIndexUrl.url;                      

                                    this.tempNoIndexUrl = [];

                                    if(error.response){

                                        if(error.response.data.error.id){

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error.id[0],
                                            });

                                        }else if(error.response.data.error.includes("already been registered")){
                                        
                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.error,
                                            });

                                        }else if(error.response.data.error.includes("doesn't support")){

                                            let subscriptionPath = window.location.origin + "/subscription";

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                dangerouslyUseHTMLString: true,
                                                message: error.response.data.error 
                                                + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                            });

                                        }else{

                                            this.$message({
                                                type: 'error',
                                                showClose: true,
                                                message: error.response.data.message,
                                            });

                                        }

                                    }else{

                                        this.$message({
                                            type: 'error',
                                            showClose: true,
                                            message: error.message,
                                        });
                                        
                                    }

                                }finally{

                                    loader.close();

                                }

                            }).catch((error) => {

                                this.noIndexUrlsList[index].url = this.tempNoIndexUrl.url;                      

                                this.tempNoIndexUrl = [];

                                this.$notify({
                                    type: "info",
                                    title: "Changes Discarded",
                                    message: "Changes have been successfully discarded.",
                                });

                            });

                        }

                    }

                }

            }

        },
        //OTG update end

        //preview form open/close & reset start
        previewNoIndexUrl(listedNoIndexUrl,i,field,e){

            if(field == 'input'){
            
                if((e.target.tagName == "TD" || e.target.dataset.open == "true") && !this.tourIsActive){
                    
                    if(listedNoIndexUrl.id != -1){

                        if(this.previewForm.id == -1){

                            MessageBox.confirm(
                                "Are you sure? unsaved changes will be discarded", 
                                "Warning", 
                                {
                                    type: "warning",
                                    confirmButtonText: "Yes",
                                    cancelButtonText: "Cancel",
                                }
                            ).then(()=>{

                                let index = this.noIndexUrlsList.findIndex((noIndexUrl) => noIndexUrl.id == -1);

                                if(index != -1){

                                    this.noIndexUrlsList.splice(index,1); 

                                }

                                if(!this.noIndexUrlSaved){

                                    let tempIndex = this.noIndexUrlsList.findIndex(x=>x.id == this.tempPreviewNoIndexUrl.id);

                                    if(tempIndex != -1){

                                        this.noIndexUrlsList[tempIndex] = this.tempPreviewNoIndexUrl;

                                    }

                                }

                                let noIndexUrl = listedNoIndexUrl;

                                this.previewForm = noIndexUrl;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.noIndexUrlIsSaving = false;

                                this.tempPreviewNoIndexUrl = _.cloneDeep(noIndexUrl);

                            }).catch(() => {})

                        }else{

                            let index = this.noIndexUrlsList.findIndex((noIndexUrl) => noIndexUrl.id == -1);

                            if(index != -1){

                                this.noIndexUrlsList.splice(index,1); 

                            }

                            if(!this.noIndexUrlSaved && (this.tempPreviewNoIndexUrl.id != listedNoIndexUrl.id)){
                                
                                let tempIndex = this.noIndexUrlsList.findIndex(x=>x.id == this.tempPreviewNoIndexUrl.id);

                                if(tempIndex != -1){

                                    this.noIndexUrlsList[tempIndex] = this.tempPreviewNoIndexUrl;

                                }

                            }

                            if(this.tempPreviewNoIndexUrl.id != '' && this.tempPreviewNoIndexUrl.id == listedNoIndexUrl.id){
                            
                                let tempIndex = this.noIndexUrlsList.findIndex(x=>x.id == this.tempPreviewNoIndexUrl.id);
                                
                                this.noIndexUrlsList[tempIndex] = _.cloneDeep(this.noIndexUrls[tempIndex]);

                                let noIndexUrl = _.cloneDeep(this.noIndexUrlsList[tempIndex]);

                                this.previewForm = this.noIndexUrlsList[tempIndex];
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.noIndexUrlIsSaving = false;

                                this.tempPreviewNoIndexUrl = _.cloneDeep(noIndexUrl);
                                
                            }else{
                                
                                let noIndexUrl = listedNoIndexUrl;

                                this.previewForm = noIndexUrl;
                                this.previewForm.index = i;

                                this.checkedItems = [];

                                this.noIndexUrlIsSaving = false;

                                this.tempPreviewNoIndexUrl = _.cloneDeep(noIndexUrl);

                            }

                        }

                    }

                }

            }

        },
        previewSearchedNoIndexUrl(listedNoIndexUrl,i){

            let index = this.noIndexUrlsList.findIndex((noIndexUrl) => noIndexUrl.id == -1);

            if(index != -1){

                this.noIndexUrlsList.splice(index,1); 

            }

            if(!this.noIndexUrlSaved){

                let tempIndex = this.noIndexUrlsList.findIndex(x=>x.id == this.tempPreviewNoIndexUrl.id);

                if(tempIndex != -1){

                    this.noIndexUrlsList[tempIndex] = this.tempPreviewNoIndexUrl;

                }

            }

            let noIndexUrl = listedNoIndexUrl;

            this.previewForm = noIndexUrl;
            this.previewForm.index = i;

            this.checkedItems = [];

            this.noIndexUrlIsSaving = false;

            this.tempPreviewNoIndexUrl = _.cloneDeep(noIndexUrl);

        },
        closePreview(){

            if(this.previewForm.isTouched){

                MessageBox.confirm(
                    "Do you want to close? Entered information will be discarded.",
                    "Warning", 
                    {
                        type: "warning",
                        confirmButtonText: "Yes",
                        cancelButtonText: "Cancel",
                    }
                ).then(async () => {

                    let index = this.noIndexUrlsList.findIndex((noIndexUrl) => noIndexUrl.id == -1);

                    if(index != -1){

                        this.noIndexUrlsList.splice(index,1); 

                    }

                    if(!this.noIndexUrlSaved){

                        let tempIndex = this.noIndexUrlsList.findIndex(x=>x.id == this.tempPreviewNoIndexUrl.id);

                        if(tempIndex != -1){

                            this.noIndexUrlsList[tempIndex] = this.tempPreviewNoIndexUrl;

                        }

                    }

                    this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                    this.$refs.noIndexUrlFormObserver.reset();

                    this.noIndexUrlSaved = false;

                }).catch(() => {})

            }else{

                let index = this.noIndexUrlsList.findIndex((noIndexUrl) => noIndexUrl.id == -1);

                if(index != -1){

                    this.noIndexUrlsList.splice(index,1); 

                }

                if(!this.noIndexUrlSaved){

                    let tempIndex = this.noIndexUrlsList.findIndex(x=>x.id == this.tempPreviewNoIndexUrl.id);

                    if(tempIndex != -1){

                        this.noIndexUrlsList[tempIndex] = this.tempPreviewNoIndexUrl;

                    }

                }

                this.previewForm = _.cloneDeep(this.defaultPreviewForm);

                this.$refs.noIndexUrlFormObserver.reset();

                this.noIndexUrlSaved = false;

            }

        },
        resetPreview(){

            let index = this.noIndexUrlsList.findIndex((noIndexUrl) => noIndexUrl.id == -1);

            if(index != -1){

                this.noIndexUrlsList.splice(index,1); 

            }

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

        },
        createNoIndexUrl(){

            if(this.noIndexUrlsLoading){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "No index URLs are being retrieved.",
                });

                return
            }

            if(this.noIndexUrlIsSaving){

                this.$notify({
                    type: "info",
                    title: "Please Wait",
                    message: "A no index URL is currently being processed.",
                });

                return

            }

            let index = this.noIndexUrlsList.findIndex(x=>x.id == -1);
            
            if(index != -1){

                // this.$notify({
                //   type: "error",
                //   title: "Can't Add New Brand",
                //   message: "you have an unsaved no index URL in the list",
                // });

                return

            }

            this.closePreview();

            this.previewForm = _.cloneDeep(this.defaultPreviewForm);

            this.previewForm.id = -1;

            let tempNoIndexUrl = this.previewForm;

            this.noIndexUrlsList.unshift(tempNoIndexUrl);

            this.checkedItems = [];

            this.$refs.noIndexUrlFormObserver.reset();

        },
        //preview form open/close & reset end

        //add/update & delete noIndexUrl start
        async validate(){

            this.$refs.noIndexUrlFormObserver.validate().then((success) => {

                if(!success){

                    const errors = Object.entries(this.$refs.noIndexUrlFormObserver.errors)
                    .map(([key, value]) => ({ key, value })).filter((error) => error["value"].length);

                    this.$refs.noIndexUrlFormObserver.refs[errors[0]["key"]].$el.scrollIntoView({behavior: "smooth", block: "center",});

                }else{

                    if(this.previewForm.id == -1){

                        this.addNoIndexUrlHandle();

                    }else{

                        this.updateNoIndexUrlHandle();

                    }

                }

            });

        },
        async addNoIndexUrlHandle(){

            let formData = new FormData()

            formData.append('url',this.previewForm.url);

            this.noIndexUrlIsSaving = true;
            try{
                let res= await this.$axios.post('/no-index-url/add',formData);
                if(res.data.status_code == '1321'){

                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let url = res.data.url;
                
                    this.$store.commit('no_index_urls_module/add_no_index_url_list',url);

                    this.resetPreview();

                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.includes("already been registered")){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.noIndexUrlIsSaving = false;

            }

        },
        async updateNoIndexUrlHandle(){

            let formData = new FormData()
      
            formData.append('id',this.previewForm.id);
            formData.append('url',this.previewForm.url);
            
            this.noIndexUrlIsSaving = true;
            try{
                let res = await this.$axios.post('/no-index-url/update',formData);
                if(res.data.status_code == "1322"){
                    
                    this.$notify({
                        type: 'success',
                        title: 'Success',
                        message: res.data.message,
                    });

                    let index = this.noIndexUrlsList.findIndex((url) => url.id == res.data.url.id);

                    let payload = {
                        index: index,
                        updatedUrl: res.data.url
                    }

                    this.$store.commit('no_index_urls_module/update_no_index_url_list',payload);

                    if(this.previewForm.id == res.data.url.id){

                        this.noIndexUrlSaved = true;

                        this.closePreview();

                    }
                
                }
            }catch(error){

                if(error.response){

                    if(error.response.data.error.id){

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.error.id[0],
                        });

                    }else if(error.response.data.error.includes("already been registered")){
                        
                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error,
                        });

                    }else if(error.response.data.error.includes("doesn't support")){

                        let subscriptionPath = window.location.origin + "/subscription";

                        this.$message({
                            type: 'error',
                            showClose: true,
                            dangerouslyUseHTMLString: true,
                            message: error.response.data.error 
                            + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                        });

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.response.data.message,
                        });

                    }

                }else{

                    this.$message({
                        type: 'error',
                        showClose: true,
                        message: error.message,
                    });

                }

            }finally{

                this.noIndexUrlIsSaving = false;

            }

        },
        deleteNoIndexUrlHandle(listedNoIndexUrl,index){

            if(!this.tourIsActive){

                MessageBox.confirm(
                    'Are you sure you want to delete no index url?',
                    'Warning',
                    {
                        type: 'warning',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'Cancel',
                    }
                ).then(async ()=>{

                    this.deletingItems.push(listedNoIndexUrl.id);
                    this.deleteLoader = true;

                    let loader = Loading.service({
                        text: "The no index URL is being removed. Please wait!",
                        fullscreen: true,
                    });

                    try{
                        let res = await this.$axios.delete('/no-index-url/remove/'+listedNoIndexUrl.id);
                        if(res.data.status_code == '1323'){

                            this.$notify({
                                type: 'success',
                                title: 'Success',
                                message: res.data.message,
                            });

                            this.$store.commit('no_index_urls_module/delete_no_index_url_list',index);
                        
                        }
                    }catch(error){

                        if(error.response){

                            if(error.response.data.error.id){

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.error.id[0],
                                });

                            }else if(error.response.data.error.includes("doesn't support")){

                                let subscriptionPath = window.location.origin + "/subscription";

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    dangerouslyUseHTMLString: true,
                                    message: error.response.data.error 
                                    + ` <a href='${subscriptionPath}' target='_blank'>Click here</a> to Upgrade your plan.`,
                                });

                            }else{

                                this.$message({
                                    type: 'error',
                                    showClose: true,
                                    message: error.response.data.message,
                                });

                            }

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.message,
                            });

                        }

                    }finally{

                        loader.close();

                        this.deletingItems = [];
                        this.deleteLoader = false;

                    }

                }).catch(() => {})

            }

        },
        //add/update & delete noIndexUrl end

        //bulk action start
        async bulkActionHandle(action){

            MessageBox.confirm(
                'Do you really want to make the changes?',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                }
            ).then(async ()=>{

                this.deletingItems = _.cloneDeep(this.checkedItems);
                this.deleteLoader = true;

                let formData = new FormData();

                formData.append('action',action);

                formData.append('noIndexUrls',JSON.stringify(this.checkedItems));

                let loader = Loading.service({
                    text: "The selected no index URLs are being removed. Please wait!",
                    fullscreen: true,
                });

                try{
                    let res = await this.$axios.post('/no-index-url/bulk-action',formData);
                    if(res.data.status_code == "1323"){

                        this.$notify({
                            type: "success",
                            title: "Success",
                            message: "No index URL(s) have been successfully deleted.",
                        });

                        let payload = {
                            action: 'delete',
                            listIds: this.checkedItems
                        }

                        this.$store.commit('no_index_urls_module/no_index_urls_bulk_action',payload);

                        this.checkedItems = [];

                    }
                }catch(error){

                    if(error.response){

                        if(error.response.data.message){

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: error.response.data.message,
                            });

                        }else{

                            this.$message({
                                type: 'error',
                                showClose: true,
                                message: "One or more of the selected Urls may have been deleted or didn't available.",
                            });

                        }

                    }else{

                        this.$message({
                            type: 'error',
                            showClose: true,
                            message: error.message,
                        });


                    }

                }finally{

                    loader.close();

                    this.deletingItems = [];
                    this.deleteLoader = false;

                }

            }).catch(() => {})

        },
        //bulk action end

        //search no index url start
        searchNoIndexUrl(){

            this.closePreview();
            this.noIndexUrlsLoading = true;

            clearTimeout(this.timer)

            this.timer = setTimeout(() => {

                if(this.searchedItem.length > 0){

                    let payload = {
                        keyword: this.searchedItem,
                    }
                    this.$store.commit('no_index_urls_module/update_searched_keyword',payload)
                    this.$store.dispatch('no_index_urls_module/fetchStoreNoIndexUrlsRequest',{ size:12, page:1, loadData: 'new'})

                }else{

                    this.$store.commit('no_index_urls_module/remove_searched_keyword')
                    this.$store.dispatch('no_index_urls_module/fetchStoreNoIndexUrlsRequest',{ size:12, page:1, loadData: 'new'})

                }
                
            }, 500)

        },
        clearSearch(){

            this.searchedItem = ''

            this.searchNoIndexUrl();

        },
        //search no index url end

        //other methods start
        isDeletingItem(id){

            let find = this.deletingItems.find(x=>x == id);

            if(find){

                return true;

            }else{

                return false;

            }

        },
        checkedItemHandle(noIndexUrlId){

            if(!this.tourIsActive && noIndexUrlId != -1 && !this.previewForm.id){

                let index = this.checkedItems.findIndex(item => item == noIndexUrlId);

                if(index != -1){

                    this.checkedItems.splice(index,1);

                }else{

                    this.checkedItems.push(noIndexUrlId);

                }

            }

        },
        toggleIsCheckedAll(){

            if(!this.tourIsActive && !this.previewForm.id){

                if((this.checkedItems.length == this.noIndexUrlsList.length)){

                    this.checkedItems = [];

                }else if(this.checkedItems.length < this.noIndexUrlsList.length){

                    this.checkedItems = [];
                    
                    this.noIndexUrlsList.forEach(noIndexUrl => {

                        if(noIndexUrl.id != -1){

                            this.checkedItems.push(noIndexUrl.id);

                        }

                    });

                }

            }

        },
        clearSelection(){

            this.checkedItems = [];

        },
        expand(){

            this.$refs.viewInputForm.classList.toggle('fullWidthSlide');

        },
        keyEvent(e){

            if(e.key == 'Escape'){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.$refs.viewInputForm.classList.remove('fullWidthSlide');

                }else if(this.$refs.viewInputForm.classList.contains('activeRow')){

                    this.closePreview();

                }

            }

        },
        //other methods end

        //tour methods start
        startNoIndexUrlsTour(){

            this.$tours.noIndexUrlsTour.start();

            this.tourIsActive = true;

            if(this.previewForm.id != ''){

                this.closePreview();

            }

        },
        handleNextStep(currentStep){

            if(currentStep + 1 == 7){

                this.createNoIndexUrl();

            }

            if(currentStep + 1 == 12){

                this.expand();

            }

            if(currentStep + 1 == 13){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                } 

            }

        },
        handlePreviousStep(currentStep){

            if(currentStep == 7){

                this.resetPreview();

            }

            if(currentStep == 12){

                if(this.$refs.viewInputForm.classList.contains('fullWidthSlide')){

                    this.expand();

                }

            }

            if(currentStep == 13){

                this.expand();

            }

        },
        handleTourStop(){

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourFinish(){

            this.closePreview();

            this.tourIsActive = false;

        },
        handleTourSkip(){

            this.closePreview();

            this.tourIsActive = false;

        },
        //tour methods end

    },
    mounted(){

        this.$refs.noIndexUrlsTable.addEventListener('scroll',this.handleScrollEvent)

        window.addEventListener('keydown',this.keyEvent);

        window.addEventListener('beforeunload', (event) => {

            let index = null;

            this.noIndexUrlsList.forEach((noIndexUrl) => {

                if(noIndexUrl.id == -1){

                    index = this.noIndexUrlsList.indexOf(noIndexUrl);

                }

            });

            if(index != null){

                event.returnValue = "Are you sure you want to leave? entered information will be lost";

            }

        });

        if(this.$route.query.search){
      
            this.searchedItem = this.$route.query.search;

            if(this.$route.query.preview == 1){

                this.isMasterSearched = true;

            }

            this.$router.replace({'query': null});

            this.searchNoIndexUrl();

        }
        
    },
    beforeMount(){

        this.noIndexUrlsLoading = true;
        try{
            if((!this.noIndexUrls || this.noIndexUrls.length == 0) && this.noIndexUrlsSearchKeyword.length == 0){

                this.$store.dispatch('no_index_urls_module/fetchStoreNoIndexUrlsRequest',{ size:12, page:1, loadData: 'new'});

            }else{

                this.noIndexUrlsList = _.cloneDeep(this.noIndexUrls)

                this.noIndexUrlsLoading = false;

            }

            if(this.noIndexUrlsSearchKeyword.length > 0){

                this.searchedItem = this.noIndexUrlsSearchKeyword;

            }
        
        }catch(error){
            
            this.$message({
                type: 'error',
                showClose: true,
                message: error.response ? error.response.data.message : error.message,
            });

        }

    },
    beforeDestroy(){

        window.removeEventListener("keydown", this.keyEvent);

    },
    beforeRouteLeave(to, from, next) {

        let index = null;

        this.noIndexUrlsList.forEach((noIndexUrl) => {

            if(noIndexUrl.id == -1){

                index = this.noIndexUrlsList.indexOf(noIndexUrl);

            }

        });

        if(window.innerWidth <= 1024){

            document.body.classList.remove('activeIpad');
            document.body.classList.add('hideSideBar');

        }

        if((this.previewForm.id || index != null) && to.fullPath != '/login'){

            MessageBox.confirm(
                'You may have unsaved changes, Proceeding will discard them',
                'Confirm',
                {
                    distinguishCancelAndClose: true,
                    confirmButtonText: 'Process',
                    cancelButtonText: 'Stay',
                }
            ).then(()=>{

                this.resetPreview();

                next();

            }).catch(()=>{});

        }else{

            next()

        }
    },
}
window.myFunction = function (event) {
    // reset all menu items
    document.querySelectorAll(".sectionTabsLink ul li a.active")
    .forEach(function (item) {
        item.classList.remove("active");
    });
    // mark as active selected menu item
    event.target.classList.add("active");
};
</script>

<style>

</style>